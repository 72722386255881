define("discourse/plugins/chat/discourse/components/chat/message-creator/new-group", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "truth-helpers/helpers/gte", "discourse/plugins/chat/discourse/components/chat/message-creator/members-count", "discourse/plugins/chat/discourse/components/chat/message-creator/members-selector", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _service, _dButton, _ajaxError, _discourseI18n, _gte, _membersCount, _membersSelector, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let NewGroup = _exports.default = (_class = (_class2 = class NewGroup extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
      _initializerDefineProperty(this, "newGroupTitle", _descriptor4, this);
      _defineProperty(this, "placeholder", _discourseI18n.default.t("chat.direct_message_creator.group_name"));
    }
    get membersCount() {
      return this.args.members?.length;
    }
    async createGroup() {
      try {
        const channel1 = await this.chat.upsertDmChannelForUsernames(this.args.members.mapBy("model.username"), this.newGroupTitle);
        if (!channel1) {
          return;
        }
        this.args.close?.();
        this.router.transitionTo("chat.channel", ...channel1.routeModels);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator__new-group-container">
        <div class="chat-message-creator__new-group">
          <div class="chat-message-creator__new-group-header-container">
            <div class="chat-message-creator__new-group-header">
              <Input
                name="channel-name"
                class="chat-message-creator__new-group-header__input"
                placeholder={{this.placeholder}}
                @value={{this.newGroupTitle}}
              />
  
              <MembersCount
                @count={{this.membersCount}}
                @max={{this.siteSettings.chat_max_direct_message_users}}
              />
            </div>
          </div>
  
          <MembersSelector
            @members={{@members}}
            @channel={{@channel}}
            @onChange={{@onChangeMembers}}
            @close={{@close}}
            @cancel={{@cancel}}
            @maxReached={{gte
              this.membersCount
              this.siteSettings.chat_max_direct_message_users
            }}
          />
  
          {{#if @members.length}}
            <div class="chat-message-creator__new-group-footer-container">
              <div class="chat-message-creator__new-group-footer">
                <DButton
                  class="btn-primary btn-flat"
                  @label="cancel"
                  @action={{@cancel}}
                />
                <DButton
                  class="btn-primary create-chat-group"
                  @label="chat.new_message_modal.create_new_group_chat"
                  @action={{this.createGroup}}
                />
  
              </div>
            </div>
          {{/if}}
        </div>
      </div>
    
  */
  {
    "id": "v7oGJUL1",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__new-group-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__new-group\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-creator__new-group-header-container\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"chat-message-creator__new-group-header\"],[12],[1,\"\\n            \"],[8,[32,0],[[24,3,\"channel-name\"],[24,0,\"chat-message-creator__new-group-header__input\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]]],[[\"@value\"],[[30,0,[\"newGroupTitle\"]]]],null],[1,\"\\n\\n            \"],[8,[32,1],null,[[\"@count\",\"@max\"],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@members\",\"@channel\",\"@onChange\",\"@close\",\"@cancel\",\"@maxReached\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[28,[32,3],[[30,0,[\"membersCount\"]],[30,0,[\"siteSettings\",\"chat_max_direct_message_users\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,1,[\"length\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-message-creator__new-group-footer-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"chat-message-creator__new-group-footer\"],[12],[1,\"\\n              \"],[8,[32,4],[[24,0,\"btn-primary btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,5]]],null],[1,\"\\n              \"],[8,[32,4],[[24,0,\"btn-primary create-chat-group\"]],[[\"@label\",\"@action\"],[\"chat.new_message_modal.create_new_group_chat\",[30,0,[\"createGroup\"]]]],null],[1,\"\\n\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@members\",\"@channel\",\"@onChangeMembers\",\"@close\",\"@cancel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/new-group.js",
    "scope": () => [_component2.Input, _membersCount.default, _membersSelector.default, _gte.default, _dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newGroupTitle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createGroup"), _class.prototype)), _class);
});