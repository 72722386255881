define("discourse/plugins/chat/discourse/components/chat-drawer/header", ["exports", "@glimmer/component", "@ember/modifier", "@ember/service", "discourse-common/helpers/i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _modifier, _service, _i18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerHeader = _exports.default = (_class = (_class2 = class ChatDrawerHeader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatStateManager", _descriptor, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{! template-lint-disable no-invalid-interactive }}
      <div
        role="region"
        aria-label={{i18n "chat.aria_roles.header"}}
        class="chat-drawer-header"
        {{on "click" @toggleExpand}}
        title={{if
          this.chatStateManager.isDrawerExpanded
          (i18n "chat.collapse")
          (i18n "chat.expand")
        }}
      >
        {{yield}}
      </div>
    
  */
  {
    "id": "Z8OTSs6o",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[24,\"role\",\"region\"],[16,\"aria-label\",[28,[32,0],[\"chat.aria_roles.header\"],null]],[24,0,\"chat-drawer-header\"],[16,\"title\",[52,[30,0,[\"chatStateManager\",\"isDrawerExpanded\"]],[28,[32,0],[\"chat.collapse\"],null],[28,[32,0],[\"chat.expand\"],null]]],[4,[32,1],[\"click\",[30,1]],null],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@toggleExpand\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/header.js",
    "scope": () => [_i18n.default, _modifier.on],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});