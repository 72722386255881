define("discourse/plugins/chat/discourse/components/chat-drawer/header/close-button", ["exports", "discourse/components/d-button", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _dButton, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CloseButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      @icon="times"
      @action={{@close}}
      @title="chat.close"
      class="btn-flat btn-link chat-drawer-header__close-btn"
    />
  
  */
  {
    "id": "ANhBR75C",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"btn-flat btn-link chat-drawer-header__close-btn\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,1],\"chat.close\"]],null],[1,\"\\n\"]],[\"@close\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/header/close-button.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = CloseButton;
});