define("discourse/plugins/chat/discourse/services/chat-audio-manager", ["exports", "@ember/service", "discourse-common/config/environment", "discourse-common/lib/get-url", "discourse-common/utils/decorators"], function (_exports, _service, _environment, _getUrl, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHAT_SOUNDS = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const AUDIO_DEBOUNCE_DELAY = 3000;
  const CHAT_SOUNDS = _exports.CHAT_SOUNDS = {
    bell: [{
      src: "/plugins/chat/audio/bell.mp3",
      type: "audio/mpeg"
    }],
    ding: [{
      src: "/plugins/chat/audio/ding.mp3",
      type: "audio/mpeg"
    }]
  };
  const DEFAULT_SOUND_NAME = "bell";
  const createAudioCache = sources => {
    const audio = new Audio();
    audio.pause();
    sources.forEach(_ref => {
      let {
        type,
        src
      } = _ref;
      const source = document.createElement("source");
      source.type = type;
      source.src = (0, _getUrl.getURLWithCDN)(src);
      audio.appendChild(source);
    });
    return audio;
  };
  let ChatAudioManager = _exports.default = (_dec = (0, _decorators.debounce)(AUDIO_DEBOUNCE_DELAY, true), (_class = class ChatAudioManager extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_audioCache", {});
    }
    setup() {
      Object.keys(CHAT_SOUNDS).forEach(soundName => {
        this._audioCache[soundName] = createAudioCache(CHAT_SOUNDS[soundName]);
      });
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this._audioCache = {};
    }
    playImmediately(soundName) {
      return this._play(soundName);
    }
    play(soundName) {
      return this._play(soundName);
    }
    _play(soundName) {
      const audio = this._audioCache[soundName] || this._audioCache[DEFAULT_SOUND_NAME];
      audio.muted = (0, _environment.isTesting)();
      if (!audio.paused) {
        audio.pause();
        if (typeof audio.fastSeek === "function") {
          audio.fastSeek(0);
        } else {
          audio.currentTime = 0;
        }
      }
      return audio.play().catch(() => {
        if (!(0, _environment.isTesting)()) {
          // eslint-disable-next-line no-console
          console.info("[chat] User needs to interact with DOM before we can play notification sounds.");
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "play", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "play"), _class.prototype)), _class));
});