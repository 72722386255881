define("discourse/plugins/chat/discourse/templates/chat-channel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <FullPageChat
    @channel={{this.model}}
    @targetMessageId={{this.targetMessageId}}
  />
  
  <ChatSidePanel>
    {{outlet}}
  </ChatSidePanel>
  */
  {
    "id": "ohzsMQzC",
    "block": "[[[8,[39,0],null,[[\"@channel\",\"@targetMessageId\"],[[30,0,[\"model\"]],[30,0,[\"targetMessageId\"]]]],null],[1,\"\\n\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"full-page-chat\",\"chat-side-panel\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-channel.hbs",
    "isStrictMode": false
  });
});