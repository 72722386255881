define("discourse/plugins/chat/discourse/templates/chat-channel-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatChannelInfo @channel={{this.model}} />
  */
  {
    "id": "+sU3YTT9",
    "block": "[[[8,[39,0],null,[[\"@channel\"],[[30,0,[\"model\"]]]],null]],[],false,[\"chat-channel-info\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-channel-info.hbs",
    "isStrictMode": false
  });
});