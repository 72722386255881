define("discourse/plugins/chat/discourse/services/chat-emoji-picker-manager", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/config/environment", "discourse-common/lib/helpers", "discourse-common/utils/decorators"], function (_exports, _tracking, _object, _runloop, _service, _rsvp, _ajax, _ajaxError, _environment, _helpers, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const TRANSITION_TIME = (0, _environment.isTesting)() ? 0 : 125; // CSS transition time
  const DEFAULT_VISIBLE_SECTIONS = ["favorites", "smileys_&_emotion"];
  const DEFAULT_LAST_SECTION = "favorites";
  let ChatEmojiPickerManager = _exports.default = (_class = class ChatEmojiPickerManager extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "closing", _descriptor2, this);
      _initializerDefineProperty(this, "loading", _descriptor3, this);
      _initializerDefineProperty(this, "picker", _descriptor4, this);
      _initializerDefineProperty(this, "emojis", _descriptor5, this);
      _initializerDefineProperty(this, "visibleSections", _descriptor6, this);
      _initializerDefineProperty(this, "lastVisibleSection", _descriptor7, this);
      _initializerDefineProperty(this, "element", _descriptor8, this);
    }
    get sections() {
      return !this.loading && this.emojis ? Object.keys(this.emojis) : [];
    }
    closeExisting() {
      this.visibleSections = DEFAULT_VISIBLE_SECTIONS;
      this.lastVisibleSection = DEFAULT_LAST_SECTION;
      this.picker = null;
    }
    close() {
      this.closing = true;
      (0, _runloop.later)(() => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        this.visibleSections = DEFAULT_VISIBLE_SECTIONS;
        this.lastVisibleSection = DEFAULT_LAST_SECTION;
        this.closing = false;
        this.picker = null;
      }, TRANSITION_TIME);
    }
    addVisibleSections(sections) {
      this.visibleSections = (0, _helpers.makeArray)(this.visibleSections).concat((0, _helpers.makeArray)(sections)).uniq();
    }
    open(picker) {
      this.loadEmojis();
      if (this.picker) {
        if (this.picker.trigger === picker.trigger) {
          this.closeExisting();
        } else {
          this.closeExisting();
          this.picker = picker;
        }
      } else {
        this.picker = picker;
      }
    }
    loadEmojis() {
      if (this.emojis) {
        return _rsvp.Promise.resolve();
      }
      this.loading = true;
      return (0, _ajax.ajax)("/chat/emojis.json").then(emojis => {
        this.emojis = emojis;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "closing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "picker", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "emojis", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "visibleSections", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_VISIBLE_SECTIONS;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lastVisibleSection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_LAST_SECTION;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "element", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeExisting", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "closeExisting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadEmojis", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadEmojis"), _class.prototype)), _class);
});