define("discourse/plugins/chat/discourse/templates/chat-channel-threads", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatThreadList @channel={{this.model}} @includeHeader={{true}} />
  */
  {
    "id": "Falkwh8k",
    "block": "[[[8,[39,0],null,[[\"@channel\",\"@includeHeader\"],[[30,0,[\"model\"]],true]],null]],[],false,[\"chat-thread-list\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-channel-threads.hbs",
    "isStrictMode": false
  });
});