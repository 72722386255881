define("discourse/plugins/chat/discourse/components/chat-notice", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/chat/discourse/components/chat/notices/mention_without_membership", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _service, _dButton, _mention_without_membership, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const COMPONENT_DICT = {
    mention_without_membership: _mention_without_membership.default
  };
  let ChatNotices = _exports.default = (_dec = (0, _service.inject)("chat-channel-notices-manager"), (_class = (_class2 = class ChatNotices extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "noticesManager", _descriptor, this);
    }
    clearNotice() {
      this.noticesManager.clearNotice(this.args.notice);
    }
    get component() {
      return COMPONENT_DICT[this.args.notice.type];
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-notices__notice">
  
        {{#if @notice.textContent}}
          <p class="chat-notices__notice__content">
            {{@notice.textContent}}
          </p>
        {{else}}
          <this.component
            @channel={{@channel}}
            @notice={{@notice}}
            @clearNotice={{this.clearNotice}}
          />
        {{/if}}
  
        <DButton
          @icon="times"
          @action={{this.clearNotice}}
          class="btn-flat chat-notices__notice__clear"
        />
      </div>
    
  */
  {
    "id": "KWUTxAH3",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-notices__notice\"],[12],[1,\"\\n\\n\"],[41,[30,1,[\"textContent\"]],[[[1,\"        \"],[10,2],[14,0,\"chat-notices__notice__content\"],[12],[1,\"\\n          \"],[1,[30,1,[\"textContent\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,0,[\"component\"]],null,[[\"@channel\",\"@notice\",\"@clearNotice\"],[[30,2],[30,1],[30,0,[\"clearNotice\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n      \"],[8,[32,0],[[24,0,\"btn-flat chat-notices__notice__clear\"]],[[\"@icon\",\"@action\"],[\"times\",[30,0,[\"clearNotice\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@notice\",\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-notice.js",
    "scope": () => [_dButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "noticesManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearNotice", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearNotice"), _class.prototype)), _class));
});