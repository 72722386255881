define("discourse/plugins/chat/discourse/modifiers/chat/on-long-press", ["exports", "@ember/destroyable", "@ember/runloop", "@ember/service", "ember-modifier", "discourse-common/lib/later", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _service, _emberModifier, _later, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function cancelEvent(event) {
    event.stopPropagation();
  }
  let ChatOnLongPress = _exports.default = (_class = class ChatOnLongPress extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _initializerDefineProperty(this, "capabilities", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    get enabled() {
      return this.capabilities.touch && this.site.mobileView;
    }
    modify(element, _ref) {
      let [onLongPressStart, onLongPressEnd, onLongPressCancel] = _ref;
      if (!this.enabled) {
        return;
      }
      this.element = element;
      this.onLongPressStart = onLongPressStart || (() => {});
      this.onLongPressEnd = onLongPressEnd || (() => {});
      this.onLongPressCancel = onLongPressCancel || (() => {});
      this.element.addEventListener("touchstart", this.handleTouchStart, {
        passive: true
      });
    }
    onCancel() {
      (0, _runloop.cancel)(this.timeout);
      if (this.capabilities.touch) {
        this.element.removeEventListener("touchmove", this.onCancel, {
          passive: true
        });
        this.element.removeEventListener("touchend", this.onCancel);
        this.element.removeEventListener("touchcancel", this.onCancel);
      }
      this.onLongPressCancel(this.element);
    }
    handleTouchStart(event) {
      if (event.touches.length > 1) {
        this.onCancel();
        return;
      }
      this.onLongPressStart(this.element, event);
      this.element.addEventListener("touchmove", this.onCancel, {
        passive: true
      });
      this.element.addEventListener("touchend", this.onCancel);
      this.element.addEventListener("touchcancel", this.onCancel);
      this.timeout = (0, _later.default)(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.element.addEventListener("touchend", cancelEvent, {
          once: true,
          passive: true
        });
        this.onLongPressEnd(this.element, event);
      }, 400);
    }
    cleanup() {
      if (!this.enabled) {
        return;
      }
      this.element.removeEventListener("touchstart", this.handleTouchStart, {
        passive: true
      });
      this.onCancel();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchStart", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype)), _class);
});