define("discourse/plugins/chat/discourse/templates/chat-channel-thread", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each (array this.model) as |thread|}}
    <ChatThread
      @thread={{thread}}
      @targetMessageId={{this.targetMessageId}}
      @includeHeader={{true}}
    />
  {{/each}}
  */
  {
    "id": "p9X5mQMf",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[30,0,[\"model\"]]],null]],null]],null],null,[[[1,\"  \"],[8,[39,3],null,[[\"@thread\",\"@targetMessageId\",\"@includeHeader\"],[[30,1],[30,0,[\"targetMessageId\"]],true]],null],[1,\"\\n\"]],[1]],null]],[\"thread\"],false,[\"each\",\"-track-array\",\"array\",\"chat-thread\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-channel-thread.hbs",
    "isStrictMode": false
  });
});