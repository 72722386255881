define("discourse/plugins/chat/discourse/modifiers/chat/track-message", ["exports", "@ember/destroyable", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ChatTrackMessage = _exports.default = (_class = class ChatTrackMessage extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "didEnterViewport", null);
      _defineProperty(this, "didLeaveViewport", null);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [didEnterViewport, didLeaveViewport] = _ref;
      this.didEnterViewport = didEnterViewport;
      this.didLeaveViewport = didLeaveViewport;
      this.intersectionObserver = new IntersectionObserver(this._intersectionObserverCallback, {
        root: document,
        threshold: 0
      });
      this.intersectionObserver.observe(element);
    }
    cleanup() {
      this.intersectionObserver?.disconnect();
    }
    _intersectionObserverCallback(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.didEnterViewport?.();
        } else {
          this.didLeaveViewport?.();
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "_intersectionObserverCallback", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "_intersectionObserverCallback"), _class.prototype)), _class);
});