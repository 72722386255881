define("discourse/plugins/chat/discourse/components/chat/thread-list/header", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _routing, _service, _replaceEmoji, _dIcon, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatThreadListHeader = _exports.default = (_class = (_class2 = class ChatThreadListHeader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _defineProperty(this, "threadListTitle", _discourseI18n.default.t("chat.threads.list"));
      _defineProperty(this, "closeButtonTitle", _discourseI18n.default.t("chat.thread.close"));
      _defineProperty(this, "showCloseButton", !this.site.mobileView);
    }
    get showBackButton() {
      return this.args.channel && this.site.mobileView;
    }
    get backButton() {
      return {
        route: "chat.channel.index",
        models: this.args.channel.routeModels,
        title: _discourseI18n.default.t("chat.return_to_channel")
      };
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-thread-list-header">
        <div class="chat-thread-header__left-buttons">
          {{#if this.showBackButton}}
            <LinkTo
              class="chat-thread__back-to-previous-route btn-flat btn btn-icon no-text"
              @route={{this.backButton.route}}
              @models={{this.backButton.models}}
              title={{this.backButton.title}}
            >
              {{icon "chevron-left"}}
            </LinkTo>
          {{/if}}
        </div>
  
        <div class="chat-thread-list-header__label">
          <span>
            {{icon "discourse-threads"}}
            {{replaceEmoji this.threadListTitle}}
          </span>
  
          {{#if this.site.mobileView}}
            <div class="chat-thread-list-header__label-channel">
              {{replaceEmoji @channel.title}}
            </div>
          {{/if}}
        </div>
  
        {{#if this.showCloseButton}}
          <div class="chat-thread-header__buttons">
            <LinkTo
              class="chat-thread__close btn-flat btn btn-icon no-text"
              @route="chat.channel"
              @models={{@channel.routeModels}}
              title={{this.closeButtonTitle}}
            >
              {{icon "times"}}
            </LinkTo>
          </div>
        {{/if}}
      </div>
    
  */
  {
    "id": "46BaxpFw",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-thread-list-header\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-thread-header__left-buttons\"],[12],[1,\"\\n\"],[41,[30,0,[\"showBackButton\"]],[[[1,\"          \"],[8,[32,0],[[24,0,\"chat-thread__back-to-previous-route btn-flat btn btn-icon no-text\"],[16,\"title\",[30,0,[\"backButton\",\"title\"]]]],[[\"@route\",\"@models\"],[[30,0,[\"backButton\",\"route\"]],[30,0,[\"backButton\",\"models\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[32,1],[\"chevron-left\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-thread-list-header__label\"],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse-threads\"],null]],[1,\"\\n          \"],[1,[28,[32,2],[[30,0,[\"threadListTitle\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-thread-list-header__label-channel\"],[12],[1,\"\\n            \"],[1,[28,[32,2],[[30,1,[\"title\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showCloseButton\"]],[[[1,\"        \"],[10,0],[14,0,\"chat-thread-header__buttons\"],[12],[1,\"\\n          \"],[8,[32,0],[[24,0,\"chat-thread__close btn-flat btn btn-icon no-text\"],[16,\"title\",[30,0,[\"closeButtonTitle\"]]]],[[\"@route\",\"@models\"],[\"chat.channel\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[32,1],[\"times\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread-list/header.js",
    "scope": () => [_routing.LinkTo, _dIcon.default, _replaceEmoji.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});