define("discourse/plugins/chat/discourse/widgets/chat-mention-notification-item", ["virtual-dom", "discourse/lib/utilities", "discourse/widgets/default-notification-item", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse-common/lib/icon-library", "discourse-i18n", "discourse/plugins/chat/discourse/lib/slugify-channel"], function (_virtualDom, _utilities, _defaultNotificationItem, _rawHtml, _widget, _iconLibrary, _discourseI18n, _slugifyChannel) {
  "use strict";

  const chatNotificationItem = {
    services: ["chat", "router"],
    text(notificationName, data) {
      const username = (0, _utilities.formatUsername)(data.mentioned_by_username);
      const identifier = data.identifier ? `@${data.identifier}` : null;
      const i18nPrefix = data.is_direct_message_channel ? "notifications.popup.direct_message_chat_mention" : "notifications.popup.chat_mention";
      const i18nSuffix = identifier ? "other_html" : "direct_html";
      return _discourseI18n.default.t(`${i18nPrefix}.${i18nSuffix}`, {
        username,
        identifier,
        channel: data.chat_channel_title
      });
    },
    html(attrs) {
      const notificationType = attrs.notification_type;
      const lookup = this.site.get("notificationLookup");
      const notificationName = lookup[notificationType];
      const {
        data
      } = attrs;
      const title = this.notificationTitle(notificationName, data);
      const text = this.text(notificationName, data);
      const html = new _rawHtml.default({
        html: `<div>${text}</div>`
      });
      const contents = [(0, _iconLibrary.iconNode)("d-chat"), html];
      const href = this.url(data);
      return (0, _virtualDom.h)("a", {
        attributes: {
          title,
          href,
          "data-auto-route": true
        }
      }, contents);
    },
    url(data) {
      const slug = (0, _slugifyChannel.default)({
        title: data.chat_channel_title,
        slug: data.chat_channel_slug
      });
      let notificationRoute = `/chat/c/${slug || "-"}/${data.chat_channel_id}`;
      if (data.chat_thread_id) {
        notificationRoute += `/t/${data.chat_thread_id}`;
      } else {
        notificationRoute += `/${data.chat_message_id}`;
      }
      return notificationRoute;
    }
  };
  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "chat-mention-notification-item", chatNotificationItem);
  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "chat-group-mention-notification-item", chatNotificationItem);
});