define("discourse/plugins/chat/discourse/templates/chat-browse-closed", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChatBrowseView @status="closed" />
  */
  {
    "id": "ZWfH9pQM",
    "block": "[[[8,[39,0],null,[[\"@status\"],[\"closed\"]],null]],[],false,[\"chat-browse-view\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-browse-closed.hbs",
    "isStrictMode": false
  });
});