define("discourse/plugins/chat/discourse/components/chat-drawer/header/right-actions", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat/thread/threads-list-button", "discourse/plugins/chat/discourse/components/chat-drawer/header/close-button", "discourse/plugins/chat/discourse/components/chat-drawer/header/full-page-button", "discourse/plugins/chat/discourse/components/chat-drawer/header/toggle-expand-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _threadsListButton, _closeButton, _fullPageButton, _toggleExpandButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatDrawerHeaderRightActions = _exports.default = (_class = (_class2 = class ChatDrawerHeaderRightActions extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
    }
    get showThreadsListButton() {
      return this.chat.activeChannel?.threadingEnabled;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-drawer-header__right-actions">
        <div class="chat-drawer-header__top-line">
          {{#if this.showThreadsListButton}}
            <ThreadsListButton @channel={{this.chat.activeChannel}} />
          {{/if}}
  
          <ToggleExpandButton @toggleExpand={{@drawerActions.toggleExpand}} />
  
          <FullPageButton @openInFullPage={{@drawerActions.openInFullPage}} />
  
          <CloseButton @close={{@drawerActions.close}} />
        </div>
      </div>
    
  */
  {
    "id": "1B8f+Cn9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-drawer-header__right-actions\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-drawer-header__top-line\"],[12],[1,\"\\n\"],[41,[30,0,[\"showThreadsListButton\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@channel\"],[[30,0,[\"chat\",\"activeChannel\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[32,1],null,[[\"@toggleExpand\"],[[30,1,[\"toggleExpand\"]]]],null],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@openInFullPage\"],[[30,1,[\"openInFullPage\"]]]],null],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@close\"],[[30,1,[\"close\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@drawerActions\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-drawer/header/right-actions.js",
    "scope": () => [_threadsListButton.default, _toggleExpandButton.default, _fullPageButton.default, _closeButton.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});